<app-windowlist  [model]="this">
    
    <div class="row">
        <div class="col-lg-2 pr-lg-0" *ngIf="filter_box">
            <app-filtercontainer [title]="'Filtra per date o pagamenti'">
                <app-filterdate [date_type]="default_filterdate" [future]="true" [class]="'card p-1'" [filter_date]="filter_date" (onSelect)="updateList($event)"></app-filterdate>
                <ul class="card small-box list-group p-1 my-2" *ngIf="methodpayments.length>0" >
                    <li class="list-group-item text-truncate pointer" [class.active]="filter_method_payment.value==''" (click)="filter_method_payment.value='';getItems()">Tutte le modalità di pagamento</li>
                    <li class="list-group-item text-truncate pointer" [class.active]="filter_method_payment.value && filter_method_payment.value.indexOf(m.name)>-1" *ngFor="let m of methodpayments"  (click)="setFilterTypeValue('filter_method_payment',m.name);">{{m.name}}</li>
                    <div class="icon"><i class="fas fa-dollar-sign"></i></div>
                </ul>
            </app-filtercontainer>
        </div>
        <div [class.col-lg-10]="filter_box" [class.col-lg-12]="!filter_box" >
            <div class="card mb-0">
                <div class="row" *ngIf="filter_box">
                    <div class="col-lg-8" [class.col-6]="mode=='embedded'">
                        <div class="input-group input-group-sm p-1" >
                            <div class="input-group-prepend">
                                <input type="text"  class="form-control form-control-sm float-right"  size="4" placeholder="ID..." [(ngModel)]="filter_id.value" (keyup.enter)="onSearchEnter()">
                            </div>
                            <input type="text" name="table_search" class="form-control float-right" placeholder="nome cliente o descrizione attività..." [(ngModel)]="filter_search.value" (keyup.enter)="onSearchEnter()">
                            <div class="input-group-append">
                                <input type="text"  class="form-control form-control-sm float-right" placeholder="comune..." [(ngModel)]="filter_search_city.value" (keyup.enter)="onSearchEnter()">
                            </div>
                            <div class="input-group-append">
                                <button type="submit" class="btn btn-default text-primary"  title="Avvia ricerca" (click)="onSearchEnter()"><i class="fas fa-search"></i></button>
                                <button class="btn btn-default text-danger"  title="Annulla ricerca" *ngIf="filter_search.value!='' || filter_search_city.value!=''" (click)="filter_search.value='';filter_search_city.value='';updateList();"><span id="searchclear" class="fas fa-times"></span></button>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2" *ngIf="mode=='embedded'">
                        <select class="form-control form-control-sm p-1" [(ngModel)]="modeShow" [class.alert-warning]="modeShow==1">
                            <option [value]="0">Mostra tutte</option>
                            <option [value]="1">Mostra solo quelle dell'anno in corso</option>
                            <option [value]="2">Mostra gli ultimi 365 giorni</option>
                        </select>
                    </div>
                    <div class="col-lg-2" *ngIf="mode!='embedded'">
                        <div class="btn-group w-100 p-1" >
                            <button [class.bg-primary]="modeView==1" type="button" class="btn btn-sm btn-default text-xs border" (click)="modeView=1">Lista</button>
                            <button [class.bg-primary]="modeView==2" type="button" class="btn btn-sm btn-default text-xs border" (click)="modeView=2;this.updateMap();">Mappa</button>
                            <button [class.bg-primary]="modeView==3" type="button" class="btn btn-sm btn-default text-xs border" (click)="modeView=3;this.updateMap();">Ibrido</button>   
                        </div>
                    </div>
                    <div class="col-lg-2" *ngIf="mode!='modal'">
                        <app-windowlistbuttons 
                        [model]="this" 
                        [table]="'activities'" 
                        [openDetailExtra]="[{'name':'id_address','value':id_address},{'name':'id_table','value':id_table},{'name':'table','value':table},{'name':'reference','value':reference}]" 
                        [funs]="[
                            {'name':'Altri comandi','value':''},
                            {'name':'Rischedula attività','value':'openRescheduleMultiple','role':'admin'}, 
                            {'name':'Crea documento da attività','value':'openModalImportValue','role':'admin'}
                        ]"
                        ></app-windowlistbuttons>
                        <!-- <div class="dropdown dropleft show"  *ngIf="user.isAdmin()">
                            <a class="btn btn-default btn-xs float-right ml-1" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" title="Altre operazioni" aria-haspopup="true" aria-expanded="false">
                                <i class="fas fa-ellipsis-v"></i>
                            </a>
                            <div class="dropdown-menu pt-0" aria-labelledby="dropdownMenuLink">
                                <small>
                                    <b class="dropdown-item border-bottom bg-light text-center text-primary">AZIONI SU ATTIVITA'</b>
                                    <button type="button" class="btn btn-xs dropdown-item"  title="Avvia il processo di rischedulazione delle attività" (click)="openRescheduleMultiple()">Rischedula le attività selezionate</button>
                                    <b class="dropdown-item border-bottom bg-light text-center text-primary">NUOVO DOCUMENTO</b>
                                    <button type="button" class="btn btn-xs dropdown-item"  title="Crea un nuovo documento con le attività selezionate" (click)="addDocument()">Crea documento dalle attività selezionate</button>
                                </small>
                            </div>
                        </div>
                        <button  title="Cestina" *ngIf="filter_status.value == '1'" (click)="trash()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right mx-1 mt-1"><i class="far fa-trash-alt"></i></button>
                        <button  title="Elimina" *ngIf="filter_status.value == '2'" (click)="delete()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right mx-1 mt-1"><i class="fas fa-trash"></i></button>
                        <button  title="Ripristina" *ngIf="filter_status.value == '2'" (click)="undo()" [disabled]="form.value.id.length == 0" class="btn btn-secondary btn-xs float-right ml-1 mt-1"><i class="fas fa-undo"></i></button>
                        <button  title="Esporta" (click)="export()" class="btn btn-secondary btn-xs float-right ml-1 mt-1"><i class="fas fa-file-export"></i></button>
                        <button (click)="openDetail(null,[{'name':'id_address','value':id_address},{'name':'id_table','value':id_table},{'name':'table','value':table},{'name':'reference','value':reference}])" class="btn btn-success btn-xs float-right ml-1 mt-1"><i class="fas fa-plus"></i></button> -->
                    </div>
                </div>
                <div class="row" *ngIf="enableFilterProduct">
                    <div class="col-6">
                        <div class="input-group input-group-sm" >
                            <input type="text" class="form-control float-right" placeholder="numero seriale o nome prodotto..." [(ngModel)]="filter_product.value" (keyup.enter)="onSearchEnter()">
                            
                        </div>
                    </div>
                </div>

                <div class="row">                    
                    <div    *ngIf="modeView==1 || modeView==3"
                            [class.col-lg-12]="modeView==1"
                            [class.col-lg-7]="modeView==3" [class.pr-lg-0]="modeView==3">
                            <!-- LIST -->
                            <ng-container *ngTemplateOutlet="listView"></ng-container>
                    </div>
                    <div    [class.d-none]="modeView==1"
                            [class.col-lg-12]="modeView==2" 
                            [class.col-lg-5]="modeView==3" [class.pl-lg-1]="modeView==3">
                            <!-- MAP -->
                            <ng-container *ngTemplateOutlet="mapView"></ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-windowlist>


<ng-template #listView>
    <form  [formGroup]="form">
        <table class="table table-nowrap table-sm table-head-fixed table-hover m-0 text-sm" >
            <thead class="bg-light">
                <th><div #checkboxselectall class="icheck-primary d-inline mr-3">
                        <input type="checkbox" title="Seleziona tutti"
                        (change)="onChangeAll($event.target.checked)" />
                    </div>
                </th>
                <th>Data
                    <button type="button" class="btn btn-tool" (click)="switchOrdering('a.date')">
                        <i class="fas fa-sort {{classOrdering('a.date')}}"></i>
                    </button>
                    <hr/>
                    Orario
                    <button type="button" class="btn btn-tool" (click)="switchOrdering('a.time')">
                        <i class="fas fa-sort {{classOrdering('a.time')}}"></i>
                    </button>
                </th>
                <th *ngIf="mode!='embedded'">
                    Anagrafica
                    <button type="button" class="btn btn-tool" (click)="switchOrdering('addr.name')">
                        <i class="fas fa-sort {{classOrdering('addr.name')}}"></i>
                    </button> 
                    <hr/>
                    Ubicazione
                    <button type="button" class="btn btn-tool" (click)="switchOrdering('a.city')">
                        <i class="fas fa-sort {{classOrdering('a.city')}}"></i>
                    </button>
                </th>
                <!--<th *ngIf="mode!='embedded'"></th>-->
                <th>
                    <select class="form-control form-control-sm" *ngIf="type_activities.length>0" [(ngModel)]="filter_id_type.value" (ngModelChange)="updateList()">
                        <option class="list-group-item pointer" [class.active]="filter_id_type.value==''" value="" (click)="setType(t);">Tipologie</option>
                        <option class="list-group-item pointer" [class.active]="filter_id_type.value==t.id" *ngFor="let t of type_activities" [value]="t.id" (click)="setType(t);">{{t.name}}</option>
                    </select>
                </th>
                <th class="text-center">
                    <select class="form-control form-control-sm" [(ngModel)]="filter_id_user.value" (ngModelChange)="updateList()" [class.alert-info]="filter_id_user.value">
                        <option value="">Operatori</option>
                        <ng-container *ngFor="let u of users">
                            <option *ngIf="u.enabled" [ngValue]="u.id">{{u.username}}</option>
                        </ng-container>
                    </select>
                </th>
                <th class="text-center">
                    <select class="form-control form-control-sm" [(ngModel)]="filter_phase.value" (ngModelChange)="updateList()"
                    [class.alert-warning]="filter_phase.value==0"
                    [class.alert-warning]="filter_phase.value==1"
                    [class.alert-success]="filter_phase.value==2"
                    [class.alert-success]="filter_phase.value==3"
                    [class.alert-warning]="filter_phase.value==4" >
                    <option value="">Stato</option>
                    <option value="0">da concordare</option>
                    <option value="1">In corso</option>
                    <option value="2">Completate</option>
                    <option value="3">Confermate</option>
                    <option value="4">Rischedulate</option>
                </select>
                </th>
                <th *ngIf="modeView==1" class="text-right">Importo</th>
                <th *ngIf="modeView==1" class="text-right">Versato

                    <button type="button" class="btn btn-tool" (click)="switchOrdering('a.paid')">
                        <i class="fas fa-sort {{classOrdering('a.paid')}}"  ></i>
                    </button>
                </th>
                <th></th>
            </thead>
            <thead *ngIf="list && list.length>0 && modeView==1">
                <tr  >
                    <td ></td>
                    <td colspan="3" class="text-left"><b>TOTALE <span *ngIf="modeShow==1" title="Possono esserci alcune attività nascoste">*</span></b></td>
                    <td colspan="2" *ngIf="mode!='embedded'"></td>
                    <td class="text-right" [class.text-red]="total_amount < 0">
                        <b>{{total_amount | currency:'EUR':true }}</b>
                    </td>
                    <td class="text-right" [class.text-red]="total_paid < 0">
                        <b>{{total_paid | currency:'EUR':true }}</b>
                    </td>
                    <td ></td>
                </tr>
            </thead>
            <tbody *ngIf="list && list.length>0">
                <ng-container *ngFor="let activities of list">
                    <tr *ngIf="isShowed(activities)" class=""
                      [class.bg-redline]="statusPaid(activities)==3"
                      [class.bg-yellowline]="statusPaid(activities)==2" 
                      [class.bg-greenline]="statusPaid(activities)==1"   
                      [class.alert-danger]="!checkOpeningTime(activities)"  
                      [class.alert-warning]="activities.address=='' || activities.city=='' || activities.latitude=='' || activities.latitude==0">
                        
                        <td>
                            <div class="icheck-primary d-inline mr-3">
                                <input type="checkbox" #checkrecord id_record="{{activities.id}}" title="{{activities.id}}"
                                (change)="onChange(activities.id, $event.target.checked)" [checked]="checkedAll"
                                />                                
                            </div>
                            <i *ngIf="activities.priority==2" title="Priorità media" class="text-orange fas fa-exclamation-circle help"></i>
                            <i *ngIf="activities.priority==3" title="Priorità elevata" class="text-fuchsia fas fa-exclamation-circle help"></i>
                        </td>
                        <td>
                            <span (click)="openDetail(activities)" title="Dettaglio attività" class="pointer">
                                {{activities.date | date:'dd/MM/yyyy'}}<br/>
                                <small>
                                    <i>ID: {{activities.id}}</i>
                                </small>
                            </span>
                            <small>
                                <div class="alert alert-info p-1 mb-0 mt-1" *ngIf="activities.phase==4">
                                    <i class="fas fa-thumbtack mr-2"></i>
                                    da rischedulare a {{activities.reschedule | date:'dd/MM/yyyy'}}<br/>
                                    <i>{{activities.note_reschedule}}</i>
                                </div>
                            </small>
                            <hr/>
                            {{activities.time}}</td>
                        <td *ngIf="mode!='embedded'">                           
                            <b title="Dettaglio anagrafica" class="pointer" (click)="openAddress(activities.id_address)">
                                <i class="fa fa-user text-gray mr-1"></i>{{activities.name}}</b>
                            <hr/>
                            {{activities.address}}<br/>
                            <b>{{activities.city}}</b> {{activities.country}}
                            <span *ngIf="activities.latitude==0" class="badge badge-warning ml-1">NO GPS</span>                         
                        </td>
                        <td>
                            <!-- <i *ngIf="activities.priority==2" title="Priorità media" class="text-orange fas fa-exclamation mr-1 help"></i>
                            <i *ngIf="activities.priority==3" title="Priorità elevata" class="text-fuchsia fas fa-exclamation mr-1 help"></i> -->
                            {{activities.type}}
                            <ng-container *ngIf="current_user.isAdmin()">                                       
                                
                                <span *ngIf="activities.documents && activities.documents.length>0"
                                    [class.text-danger]="activities.documents[0].paid<=0" 
                                    [class.text-success]="activities.documents[0].paid>0"
                                    class="ml-1">
                                    <i class="fas fa-file-invoice-dollar help" title="Ci sono documenti di pagamento"></i>
                                </span>

                                <!--
                                <span *ngIf="activities.documents && activities.documents.length>0"
                                        [class.text-danger]="activities.documents[0].cashflow.length==0" 
                                        [class.text-success]="activities.documents[0].cashflow.length>0"
                                        [class.pointer]="activities.documents[0].cashflow.length>0" 
                                        class="ml-1">
                                        <i class="fas fa-file-invoice-dollar" (click)="openCashflow(activities.documents[0].cashflow[0].id)" title="Movimenti associati ai documenti: {{activities.documents[0].cashflow.length}}"></i>
                                </span>
                                -->

                                <span *ngIf="(activities.amount>0 || activities.paid>0) && activities.enable_accounting"  
                                    [class.text-danger]="activities.id_cashflow==0" 
                                    [class.text-warning]="activities.id_cashflow>0 && !hasCashflowPaid(activities)" 
                                    [class.text-success]="activities.id_cashflow>0 && hasCashflowPaid(activities)"
                                    [class.pointer]="activities.id_cashflow>0" 
                                    class="ml-1">
                                    <i class="fas fa-dollar-sign pointer" (click)="openCashflow(activities.id_cashflow)" title="Dettaglio movimenti associati"></i>
                                </span>
                            </ng-container>   
                            <i *ngIf="activities.id_form>0" title="È stato compilato un modulo" class="fab fa-wpforms text-info help ml-2"></i>
                            <br/>
                            <small>{{activities.description}}</small>
                            <ng-container *ngIf="activities.note">
                                <hr>
                                <small><i class="fas fa-thumbtack text-info mr-2"></i>{{activities.note}}</small>
                            </ng-container>

                            <ng-container *ngIf="current_user.isAdmin()">
                                <ng-container *ngIf="activities.documents && activities.documents.length>0">
                                    <br/>
                                    <div class="badge border pointer" *ngFor="let d of activities.documents" (click)="openDocument(d);" title="Dettaglio documento">
                                        <i class="fa fa-file-alt mr-1"></i>Documento n. <b>{{d.reference}}{{d.referenceprefix}}</b> del <b>{{d.date}}</b>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </td>
                        <td class="text-center"><small>{{activities.username}}</small></td>
                        <td class="text-center">
                            <i *ngIf="activities.phase==4" title="Doppio click per rischedulare l'attività" (dblclick)="reschedule(activities)" class="text-warning fa fa-lg fa-2x fa-exclamation pointer"></i>
                            <i *ngIf="activities.phase==3" title="Attività confermata!" class="text-success fa fa-lg fa-2x fa-check-double help"></i>
                            <i *ngIf="activities.phase==2" title="Doppio click per confermare l'attività" (dblclick)="confirmActivities(activities)" class="text-success fa fa-2x fa-check pointer"></i>
                            <i *ngIf="activities.phase==1" title="Attività in corso..." class="text-warning far fa-lg fa-2x fa-clock help"></i>
                            <i *ngIf="activities.phase==0" title="Attività da confermare da parte del cliente" class="text-warning fas fa-lg fa-2x fa-exclamation-triangle"></i>
                            <br>
                            <small *ngIf="!checkOpeningTime(activities)" class="help" title="Secondo gli orari memorizzati, in questo momento la sede è chiusa">Chiuso ?</small>
                        </td>
                        <td *ngIf="modeView==1" class="text-right">
                            {{activities.amount*(1+activities.tax/100) | currency:'EUR':true }}
                        </td>
                        <td *ngIf="modeView==1" class="text-right">
                        <b [class.text-danger]="activities.paid<activities.amount*(1+activities.tax/100)">{{activities.paid | currency:'EUR':true }}</b><br/>
                            <span *ngIf="activities.method_payment" class="badge border"><b>{{activities.method_payment}}</b></span>
                            <small>
                                <div class="alert alert-info p-1 mt-1 mb-0 text-center" *ngIf="activities.paid_description!=''">
                                    <i class="fas fa-thumbtack mr-2"></i>{{activities.paid_description}}
                                </div>
                                <div class="alert alert-info p-1 mt-1 mb-0 text-center" *ngIf="activities.paid_type==3">
                                    <i class="fas fa-thumbtack mr-2"></i>Pagamento rischedulato<br>al {{activities.reschedule_payment | date:"d/M/Y"}}
                                </div>
                            </small>
                        </td>
                        <td>
                            <button type="button" *ngIf="mode=='modal' && isSelectable" class="btn btn-secondary btn-xs float-right" (click)="selectRecord(activities);">
                                <span class="d-none d-sm-inline-block m-1">Seleziona</span><i class="fas fa-chevron-right"></i>
                            </button>
                            <button *ngIf="current_user.isAdmin()" type="button" (click)="openDetail(activities)" title="Modifica / Vedi" class="btn btn-secondary btn-xs float-right ml-1 mt-1">
                                <i class="fas fa-pencil-alt"></i></button>
                            <button type="button" *ngIf="mode!='modal'" (click)="notify(activities);" title="Notifica" class="btn btn-primary btn-xs float-right ml-1 mt-1">
                                <i class="far fa-paper-plane"></i>
                            </button>
                            <button type="button" title="Centra sulla mappa" *ngIf="modeView==3" class="btn btn-outline-secondary btn-xs float-right ml-1 mt-1" (click)="centerOnMap(activities);">
                                <i class="fas fa-compass"></i>
                            </button>
                            <button type="button" title="Pianifica attività" class="btn btn-outline-secondary btn-xs float-right ml-1 mt-1" (click)="openModalPlanner(activities);">
                                <i class="far fa-calendar-alt"></i>
                            </button>                           
                        </td>
                    </tr>
                </ng-container>
            </tbody>            
            <tfoot *ngIf="list && list.length>0 && modeView==1">
                <tr>
                    <td ></td>
                    <td colspan="3" class="text-left"><b>TOTALE <span *ngIf="modeShow==1" title="Possono esserci alcune attività nascoste">*</span></b></td>
                    <td colspan="2" *ngIf="mode!='embedded'"></td>
                    <td class="text-right" [class.text-red]="total_amount < 0">
                        <b>{{total_amount | currency:'EUR':true }}</b>
                    </td>
                    <td class="text-right" [class.text-red]="total_paid < 0">
                        <b>{{total_paid | currency:'EUR':true }}</b>
                    </td>
                    <td ></td>
                </tr>
            </tfoot>
        </table>
        <div *ngIf="!list || list.length==0" class="m-5 text-center text-secondary">
            <i class="icon fas fa-info-circle mr-2"></i><i>{{messageStatus}}</i>            
        </div>
    </form>
    <div class="card-footer clearfix py-1">
        <app-pagination [model]="this" ></app-pagination>
    </div>
</ng-template>


<ng-template #mapView>
    <div id="map" [style.min-height]="map_height"></div>
   
    <div *ngIf="loadingMap" id="loading" [style.min-height]="map_height">
        Caricamento in corso...
    </div>
    <div class="legend">
        <button type="button" title="Mostra legenda" class="btn" *ngIf="legend_collapse" (click)="legend_collapse=false">
            <i class="fas fa-info fa-chevron-left" ></i></button>
        <button type="button" title="Nascondi legenda" class="btn float-right" *ngIf="!legend_collapse" (click)="legend_collapse=true">
            <b>CHIUDI</b><i class="fa fa-chevron-right ml-2"></i></button>
        <table class="w-100" *ngIf="!legend_collapse">
            <tr *ngFor="let l of layers">                
                <td>
                    <i style="position:relative;display: inline-block;vertical-align: middle;" class="leaflet-marker-icon extra-marker extra-marker-{{l.icon}}-{{l.color}}"></i>
                </td>
                <td>
                    <small>{{l.name}}</small>
                </td>
                <td>
                    <input class="mx-2" type="checkbox" [(ngModel)]="l.status" (change)="onChangeLayer(l)">
                </td>
            </tr>
        </table>

        <!-- <ul class="list-group" *ngIf="!legend_collapse">
            <li  class="list-group-item" *ngFor="let l of layers">
                <div class="row">
                    <div class="col-2">
                        <input type="checkbox" [(ngModel)]="l.status" (change)="onChangeLayer(l)">
                    </div>
                    <div class="col-10">
                        <i style="position:relative;display: inline-block;vertical-align: middle;" class="leaflet-marker-icon extra-marker extra-marker-{{l.icon}}-{{l.color}}"></i>
                        <small style="display: inline-block;vertical-align: middle;">{{l.name}}</small>
                    </div>
                </div>
                
            </li>
        </ul> -->
       
    </div>
   
</ng-template>
       
<ng-template #rescheduleModal>
    <div class="modal-header">
        <b>Rischedula attività</b>
        <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="closeReschedule()" >
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <label>Azione</label>
            <select class="form-control form-control-sm" name="rescheduleAction" [(ngModel)]="rescheduleAction">
                <option value="1">Crea nuova voce di agenda</option>
                <option value="2">Modifica data di questa attività</option>
            </select>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-success" (click)="confirmReschedule()">Conferma</button>
        <button class="btn btn-secondary" (click)="closeReschedule()">Annulla</button>
    </div>
</ng-template>


<ng-template #modalRescheduleMultiple>
    <div class="modal-header">
        <b>Rischedula più attività</b>
        <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="closeRescheduleMultiple()" >
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <table class="table">
            <thead>
                <th>Attività</th>
                <th>Data</th>
                <th>Operatore</th>
            </thead>
            <tbody>
                <tr *ngFor="let activities of rescheduleRecords;let i=index">
                    <td>
                        {{activities.type}}<br/><br/>
                        <b>{{activities.name}}</b><br/>
                        {{activities.address}}<br/>
                        {{activities.city}}

                        <!--<app-reference [reference]="activities.reference" [table]="activities.table"></app-reference>-->

                    </td>
                    <td>
                        <input type="date" class="form-control form-control-sm" name="date_{{i}}" [(ngModel)]="activities.date">
                    </td>
                    <td>
                        <select class="form-control form-control-sm" name="user_{{i}}" [(ngModel)]="activities.id_user">
                            <ng-container *ngFor="let u of users">
                                <option *ngIf="u.enabled" [ngValue]="u.id">{{u.username}}</option>
                            </ng-container>
                        </select>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="modal-footer">
        <button class="btn btn-secondary" (click)="closeRescheduleMultiple()">Annulla</button>
        <button class="btn btn-success" (click)="confirmRescheduleMultiple()">Conferma</button>
    </div>
</ng-template>



<ng-template #modalPlanner>
    <div class="modal-header">
        Pianifica attività
    </div>
    <div class="modal-body">
        <label>Cliente</label>
        <div class="card">
            <div class="card-body">
                <h4>{{activityPlanner.address.name}}</h4>
                <h6>{{activityPlanner.address.address}}, {{activityPlanner.address.city}}
                    <br/>
                    <br/>
                    Contatti:
                    <ul>
                        <li *ngFor="let c of activityPlanner.address.contacts">
                            <b>{{c.value}}</b> ({{c.type}} {{c.note}})
                        </li>
                    </ul>
                    <br/>
                    <br/>
                    Saldo cliente: <span [class.text-danger]="activityPlanner.address.balance<0" [class.text-success]="activityPlanner.address.balance>0">{{activityPlanner.address.balance | currency:"EUR"}}</span>
                </h6>
            </div>
        </div>
        
        <label>Attività</label>
        <div class="card">
            <div class="card-body">
                <p><b>{{activityPlanner.activity.type}}</b>
                    <br/>
                    {{activityPlanner.activity.subject}} {{activityPlanner.activity.description}}
                    <br/>
                    Importo: <span class="text-success">{{activityPlanner.activity.amount*(1+activityPlanner.activity.tax/100) | currency:"EUR"}}</span>
                </p>
            </div>
        </div>
        <label>Oggetto presa contatto</label>
        <input type="text" class="form-control form-control-sm" name="activity_planner_name" [(ngModel)]="activityPlanner.subject">

        <label>Esito della presa contatto</label>
        <input type="text" class="form-control form-control-sm" name="activity_planner_description" [(ngModel)]="activityPlanner.description">

        <label>Note da riportare nell'attività (visibile all'operatore)</label>
        <textarea type="text" class="form-control form-control-sm" name="activity_planner_note" [(ngModel)]="activityPlanner.note"></textarea>

        <label>Nuovo stato dell'attività</label>
        <select class="form-control form-control-sm"  name="activity_planner_phase" [(ngModel)]="activityPlanner.phase">
            <option [ngValue]="0">Da concordare</option>
            <option [ngValue]="1">In corso</option>
        </select>

        <label>Operatore assegnato all'attività</label>
        <select class="form-control form-control-sm" name="activity_planner_id_user" [(ngModel)]="activityPlanner.id_user">
            <ng-container *ngFor="let u of users">
                <option *ngIf="u.enabled" [ngValue]="u.id">{{u.username}}</option>
            </ng-container>
        </select>

        <label>Data di esecuzione attività</label>
        <input type="date" class="form-control form-control-sm" name="activity_planner_date" [(ngModel)]="activityPlanner.date">


    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeModalPlanner()">Annulla</button>
        <button type="button" class="btn btn-success" [disabled]="activityPlanner.subject=='' || activityPlanner.description==''" (click)="savePlanner()">Salva</button>
        
    </div>


</ng-template>