import { Component, OnInit,Input ,ViewChild} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Helper } from 'projects/core/src/helper';
import { Model } from 'projects/core/src/include/model';
import { Deadlines } from '../../../db/deadlines';
import { Type } from '../../../db/type';
import { ReferenceComponent } from '../../../modules/reference/reference.component';
import { AddressesService } from '../../../services/addresses.service';
import { DeadlinesService } from '../../../services/deadlines.service';
import { MethodpaymentsService } from '../../../services/methodpayments.service';
import { TypesService } from '../../../services/types.service';
import { formatDate } from '@angular/common';
import { FinancialAccountsService } from '../../../services/financialaccounts.service';
import { Globals } from 'projects/core/src/globals';
import { ThisReceiver } from '@angular/compiler';
import { ShopsService } from '../../../services/shops.service';
import { UserService } from 'projects/core/src/lib/user.service';
import { User } from 'projects/core/src/include/structures';
import { CashFlowDetailsComponent } from '../../cash-flow/cash-flow-details/cash-flow-details.component';


@Component({
  selector: 'app-deadline-details',
  templateUrl: './deadline-details.component.html',
  styleUrls: ['./deadline-details.component.sass']
})
export class DeadlineDetailsComponent extends Model<Deadlines> implements OnInit {

  @Input()
  id_address:number;

  @Input()
  table:string;

  @Input()
  id_table=0;

  @Input()
  date:Date;

  @Input()
  methodpayment:string;

  @ViewChild("referenceRecord")
  referenceRecord:ReferenceComponent;

  type_deadline:Type[];
  @Input()
  reference:any;

  @Input()
  amount:number=0;

  @Input()
  tax:number=-1;

  @Input()
  id_type:number=0;

  @Input()
  description:string="";

  methodpayments=[];

  rate:number=100;
  sources=[];

  isGetReference:boolean=false; //indica che la finestra è stata aperta con un record di riferimento da agganciare

  field_required=['date','id_type'];
  financialaccounts=[];
  shops=[];
  users:User[];
  constructor(
    private route1: ActivatedRoute,
    private deadlinesService: DeadlinesService,
    private addressesService:AddressesService,
    private typesService: TypesService,
    private methodpaymentsService:MethodpaymentsService,
    private financialAccountsService:FinancialAccountsService,
    private shopsService:ShopsService,
    private userService:UserService,


   
    ) {
      super(route1,deadlinesService); 
    
    
  }

  ngOnInit(): void {
   
    this.title = 'Agenda Dettaglio';
   
    if(this.mode!="modal")
      this.route1.params.subscribe((params) => {
        this.id = params.id;
        if(params.mode!=undefined)
          this.isGetReference=true;
    
      });

      this.financialAccountsService.getAccounts().subscribe((items_c)=>{
        this.financialaccounts=items_c;
      });

      const default_address=Globals.parameters.get("general").getParam("default_address");
     

      this.userService.getUsers().subscribe((items)=>{
        this.users=items;
        this.shopsService.getItemsByAddress(default_address).subscribe((items)=>{
          this.shops=items;
          this.getItem();
        });
       
      });


    this.typesService.getTypes("deadlines").subscribe((items)=>{
      this.type_deadline=items;
      this.methodpaymentsService.getMethods().subscribe((items_m)=>{
        this.methodpayments=items_m;
        this.getItem();
      });
      
   });
   this.today=new Date();
    //carica il riferimento
    this.sources=Globals.parameters.get("cash-flow")?.getParam("sources",[]);


    this.afterGetItem=()=>{
      
      if(this.id==0){
        this.record={} as Deadlines;
        
        this.record.id_type=this.id_type?this.id_type:this.type_deadline[0].id;
        this.record.date=this.date?this.date:Helper.convertDateControl(null);
        this.record.customfields=[];
        this.record.amount=this.amount;
        this.record.tax=this.tax==-1?Globals.parameters.get("general").getParam("default_tax"):this.tax;
        this.record.methodpayment=this.methodpayment;
        this.record.type_amount=this.record.type_amount?this.record.type_amount:2;
        this.record.id_table=this.id_table;
        this.record.table=this.table;
        this.record.id_user=Globals.user.id;
        this.record.created_from=this.shops[0].id;
        if( this.id_address>0){
          this.addressesService.getItem(this.id_address).subscribe((item)=>{
            this.record.reference=item;
            this.record.id_table=item.id;
            this.record.table="addresses";
            this.record.id_address=this.id_address;
            
          });
        }

        if(this.reference){
          this.setReference(this.reference,this.table);
        }
        this.record.description=this.description;
      }

      this.calculateGross();
      /*if (this.reference)
        this.record.description="Pagamento doc. n° "+this.reference.reference+" del "+formatDate(this.reference.date,'dd-MM-yyyy',"it-IT");
      */
    }


   

    this.afterSelectionRecord=((r,table)=>{
        this.setReference(r,table);
      
     });

 

  }

  

  calculateGross(){
    this.record.gross=parseFloat((this.record.amount*(1+this.record.tax/100)).toFixed(2));
  
  }
  
  calculateNet(){
    this.record.amount=parseFloat((this.record.gross/(1+this.record.tax/100)).toFixed(2));
  
  }

  calculateRate(){

    if (!this.record.description) this.record.description="";
    this.record.description+=" ("+this.rate+"% di €"+this.record.amount+")";
    this.record.amount_description="rata ("+this.rate+"% del totale di "+this.record.amount+" Euro)";
    this.record.amount=parseFloat((this.record.amount/100*this.rate).toFixed(2));   
    this.calculateGross();
      
  }

  setReference(r,table):void{
    this.record.reference=r;
    if(this.record.reference!=null){
      this.record.table=table;
      this.record.id_table=r['id'];
      this.record.id_address=ReferenceComponent.getIdAddress(table,this.record.reference);
    }else{
      this.record.table="";
      this.record.id_table=0;
      this.record.id_address=0;
    }
   
  }
  

  connectActivity(record){
    this.setReference(record,record?record.table_reference:null);
  }


  checkBeforeToday(d){
    
    if (d) {
      let date=d!=null?Helper.convertString2Date(d):false;
      let today=new Date();
      if(date<today){
        return true;
      }
      return false;
    }

  }


  showPayment(id_cashflow){
   
    Globals.modal.showModal(CashFlowDetailsComponent,[
      {"name":"mode","value":"modal"},
      {"name":"id","value":id_cashflow}
    ],()=>{});
 

}

}
