<section class="content">
    <div class="">
        <ul class="nav nav-tabs b-1" id="myTab" role="tablist">
            <li class="nav-item">
                <a class="nav-link" [class.active]="tab==1" (click)="tab=1">
                    <i class="fas fa-list-ul"></i><span class="d-none d-sm-inline-block ml-2">Lista</span></a>
            </li>
            <li class="nav-item" >
                <a class="nav-link" [class.active]="tab==2" (click)="tab=2">
                    <i class="fas fa-balance-scale"></i><span class="d-none d-sm-inline-block ml-2">Riepilogo</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [class.active]="tab==3" (click)="tab=3">
                    <i class="fas fa-piggy-bank"></i><span class="d-none d-sm-inline-block ml-2">Stato cassa</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [class.active]="tab==4" (click)="tab=4">
                    <i class="fas fa-chart-bar"></i><span class="d-none d-sm-inline-block ml-2">Statistiche</span></a>
            </li>
        </ul> 
        <div class="tab-content p-1" id="myTabContent" >
            <div class="tab-pane fade show" [class.active]="tab==1" *ngIf="tab==1">
                <app-cash-flow></app-cash-flow>
            </div>
            <div class="tab-pane fade show" [class.active]="tab==2" *ngIf="tab==2">
                <app-cash-flow-overview></app-cash-flow-overview>
            </div>
            <div class="tab-pane fade show" [class.active]="tab==3" *ngIf="tab==3">
                <app-cashregister></app-cashregister>
            </div>
            <div class="tab-pane fade show" [class.active]="tab==4" *ngIf="tab==4">
                <app-cash-flow-analytics></app-cash-flow-analytics>
            </div>
        </div>
    </div>
</section>