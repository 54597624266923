<div class="modal-header">
    <b>Invio guidato documenti allo SdI</b>
    <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="close()" >
          <i class="fas fa-times"></i>
        </button>
    </div>
</div>
<div class="modal-body" >
    <div *ngIf="documentToSend>0">
        <i class="p-2 fas fa-file-invoice fa-4x text-primary d-inline-block"></i>    
        <div class="p-2 text-primary d-inline-block">
            Ottimo!<br>           
            <span *ngIf="documentToSend==1">Un documento è idoneo all'invio al Sistema di Interscambio.</span>
            <span *ngIf="documentToSend>1">{{documentToSend}} documenti sono idonei all'invio al Sistema di Interscambio.</span>
        </div>
    </div>
    <ng-container>
        <div class="p-5 text-center" *ngIf="documentToSend==0 && !afterSend">
                <i class="fa fa-exclamation-triangle text-warning mr-2"></i>
                <span *ngIf="documents.length==1">Il documento selezionato non è idoneo all'invio al Sistema di Interscambio.</span>
                <span *ngIf="documents.length>1">Nessuno dei {{documents.length}} documenti selezionati è idoneo all'invio al Sistema di Interscambio.</span>
        </div>
        <table class="table table-sm" style="height:5rem; max-height:60vh" *ngIf="documentToSend>0 && !afterSend">
            <thead class="text-primary" >
                <th>Tipo</th>
                <th>Data</th>
                <th>Rif</th>
                <th>Anagrafica</th>
                <th>Importo</th>
                <th style="text-align:center">Stato SdI</th>
                <th>Verifica</th>
                <th></th>
            </thead>
            <tbody>               
                <ng-container *ngFor="let record of documents">                    
                    <tr *ngIf="(record.typedocument==1 || record.typedocument==2 || record.typedocument==12 || record.typedocument==3) && (record.sdi_status==0 || record.sdi_status==3 || record.sdi_status==-1)"
                        [class.text-gray]="record.sdi_message">
                        <td>
                            <span *ngIf="record.typedocument==1"  title="Fattura" class="badge bg-olive">FS</span>
                            <span *ngIf="record.typedocument==2"  title="Fattura Accompagnatoria" class="badge bg-teal" >FV</span>
                            <span *ngIf="record.typedocument==12" title="Fattura Acconto" class="badge bg-lime" >FA</span>
                            <span *ngIf="record.typedocument==3"  title="Nota di Credito" class="badge bg-maroon">NC</span>
                        </td>
                        <td>{{record.date| date:"dd-MM-YYYY"}}</td>
                        <td>{{record.reference}} {{record.referenceprefix}}</td>
                        <td>{{record.addressItem.name}}</td>
                        <td>{{record.total | currency:"&euro; "}}</td>
                        <td class="text-center">
                            <i  title="da inviare" *ngIf="record.sdi_status==0 || record.sdi_status==-1" class="text-info fas fa-paper-plane help"></i>
                            <i  title="Inviata, in attesa di risposta" *ngIf="record.sdi_status==1" class="text-warning fa fa-clock help"></i>
                            <i  title="Consegnata" *ngIf="record.sdi_status==2" class="text-success fa fa-check help"></i>
                            <i  title="In errore" *ngIf="record.sdi_status==3" class="text-danger fa fa-exclamation-triangle help"></i>
                            <i  title="Consegnata ma con mancato recapito" *ngIf="record.sdi_status==4" class="text-success fa fa-check help"></i>
                        </td>
                        <td style="max-width:16rem; overflow:hidden" >
                            <span class="text-danger" *ngIf="record.sdi_message">
                                <small>{{record.sdi_message.substring(36) | json}}</small><br>
                                <button class="btn btn-outline-danger btn-xs mt-1" *ngIf="record.filexml" (click)="downloadXML(record)"><i class="fas fa-file fa-file-code mr-1"></i> Apri file XML</button>
                            </span>
                            <b class="text-success" *ngIf="!record.sdi_message && VerifyDone ">
                                Ok
                            </b>
                        </td>
                        <td>
                            <button title="Vedi/Modifica" class="btn btn-secondary btn-sm float-right" (click)="VerifyDone=false;openDetail(record)"><i class="fa fa-pencil-alt"></i></button>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
        <div class="mt-4">
            <button *ngIf="documentToSend>0 && !afterSend" [class.btn-outline-success]="VerifyDone" class="btn btn-outline-primary" (click)="checkDocuments()">
                Verifica integrità documenti<span class="badge border ml-3" *ngIf="VerifyDone"><b>Fatto!</b></span></button>
            <ng-container *ngIf="VerifyDone">
                <button *ngIf="VerifyDone && documentToSend>0" class="btn btn-primary ml-2" (click)="sendToSdi()">
                    <span *ngIf="documentToSend==1">Invia il documento verificato allo SdI</span>
                    <span *ngIf="documentToSend>1">Invia i documenti verificati allo SdI</span>
                    <i *ngIf="!afterSend" class="fas fa-paper-plane ml-2"></i>
                    <b class="ml-3" *ngIf="afterSend">Ok <i class="fa fa-chevron-right"></i></b>
                </button>
                <span class="btn btn-warning ml-2" *ngIf="!afterSend && documentToSend==0">Nessun documento da inviare!</span>
            </ng-container>
            <ng-container *ngIf="afterSend">
                <div class="text-success m-2" *ngIf="total_success>0">
                    <i class="fas fa-check fa-2x mr-2"></i>
                    <span *ngIf="total_success>1">{{total_success}} documenti inviati correttamente allo SdI</span>
                    <span *ngIf="total_success==1">Documento inviato correttamente allo SdI</span>
                </div>                    
                <div class="text-danger m-2" *ngIf="total_errors>0">
                    <i class="fas fa-exclamation fa-2x mr-2"></i>
                    <span *ngIf="total_errors>1">Attenzione: Ci sono {{total_errors}} documenti che presentano errori nell'invio allo SdI</span>
                    <span *ngIf="total_errors==1">Attenzione: C'è un documento che presenta errori nell'invio allo SdI</span>
                </div>
            </ng-container>
        </div>
    </ng-container>
</div>
<div class="modal-footer">
    <button class="btn btn-sm btn-secondary" (click)="close()">Chiudi</button>
</div>